import SidebarService from 'src/components/ui/SidebarService/SidebarService'

import PaymentOptions from './PaymentOptions'
import PaymentConditions from './PaymentConditions'
import InstallmentsRules from './InstallmentsRules'

import '../styles.scss'

const FormaPagamento = () => {
  return (
    <>
      <section className="section-services form-payment_container w-full">
        <div className="av-container">
          <div className="av-row section-services__flex">
            <div className="av-col-xs-24 av-col-md-7 section-services__column">
              {/* <!-SideBar FormaPagamento-> */}
              <SidebarService />
            </div>

            <div className="av-col-xs-24 av-col-md-17">
              {/* <!- FormaPagamento Products-> */}
              <div className="services-content">
                <div className="section__top align-center">
                  <h1 className="text-blue italic text-2xl font-bold text-center">
                    FORMAS DE PAGAMENTO
                  </h1>
                  <h2 className="text-gray selection:italic italic text-xl font-bold text-left">
                    CARTÕES DE CRÉDITO
                  </h2>
                  <p className="text-left mb-2">
                    O e-commerce e lojas Decathlon oferecem como formas de
                    pagamento os cartões de crédito com bandeiras:
                  </p>
                  <div className="flex gap-1">
                    <span className="card-visa" />
                    <span className="card-mastercard" />
                    <span className="card-elo" />
                    <span className="card-diners" />
                    <span className="card-amex" />
                  </div>
                </div>
                <div className="hro">
                  <PaymentConditions />
                  <InstallmentsRules />
                  <h2 className="text-gray italic text-xl font-semibold text-left">
                    Pix
                  </h2>
                  <p className="text-left text-sm">
                    O Pix é um método rápido, seguro e prático para realizar
                    pagamentos eletrônicos, permitindo uma experiência de compra
                    ainda mais simples e ágil. Ao finalizar sua compra em nosso
                    site ou aplicativo, selecione a opção de pagamento via Pix.
                    Você receberá as instruções para realizar o pagamento no
                    aplicativo de seu banco ou instituição financeira. Assim que
                    recebermos a confirmação do pagamento, iniciaremos o
                    processamento de seu pedido para envio. Lembrando que o
                    pagamento via Pix está disponível somente para produtos
                    vendidos e entregues pela Decathlon.
                  </p>
                  <h2 className="text-gray italic text-xl font-semibold text-left">
                    BOLETO
                  </h2>
                  <p className="text-left text-sm">
                    No boleto, você efetua o pagamento à vista. Imprima o
                    documento e efetue o pagamento em qualquer agência bancária,
                    casa lotérica ou pelo internet banking. Nós seremos
                    notificados em até 72 horas, liberando então o seu pedido. O
                    prazo de entrega passa a valer após recebermos a notificação
                    de confirmação de pagamento.
                  </p>

                  <h2 className="text-gray italic text-xl font-semibold text-left">
                    CARTÃO DE CRÉDITO DECATHLON ITAÚ
                  </h2>
                  <p className="text-left text-xs mt-4">
                    <strong className="font-bold text-sm">
                      O cartão Decathlon Itaú proporciona benefícios exclusivos,
                      são eles:
                    </strong>
                  </p>
                  <ul className="form-payment_installments text-left pl-10">
                    <li className="text-sm">Anuidade grátis no 1º ano</li>
                    <li className="text-sm">Frete Grátis</li>
                    <li className="text-sm">
                      Parcelamento em até 15x sem juros
                    </li>
                    <li className="text-sm">Ofertas em parceiros</li>
                  </ul>
                  <p className="text-sm mt-2">Mecânica dos benefícios</p>
                  <p className="text-sm mt-4">
                    <strong>Anuidade grátis no 1º ano</strong>
                  </p>
                  <p className="text-sm mt-2">
                    Propostas realizadas até 30/04/22 e aprovadas dentro do
                    período terão anuidade grátis por 12 meses. Cliente não irá
                    pagar anuidade no primeiro ano independentemente do valor
                    gasto na fatura mensal. Válido para todos os clientes que
                    contratarem o cartão até 30/04.
                  </p>
                  <p className="text-sm mt-4">
                    <strong>Frete Grátis</strong>
                  </p>
                  <p className="text-sm mt-2">
                    Compras com o cartão Decathlon Itaú pelo site ou APP
                    Decathlon, sem valor mínimo, válido para todos os produtos e
                    válido para todas as regiões do Brasil. Benefício perene do
                    cartão, sem prazo de validade.
                  </p>
                  <p className="text-sm mt-4">
                    <strong>Parcelamento em até 15x sem juros</strong>
                  </p>
                  <p className="text-sm mt-2">
                    15x sem juros em compras no cartão Decathlon com parcela
                    mínima de R$ 50. Condições gerais: Parcelamento em até 15x
                    sem juros com parcela mínima de R$ 50,00. Válido para todos
                    os produtos em compras no site, app e na loja.
                  </p>
                  <p className="text-sm mt-4">
                    <strong>Ofertas em parceiros</strong>
                  </p>
                  <p className="text-sm mt-2">
                    *Benefícios válidos apenas nos ítens vendidos e entregues
                    pela Decathlon.
                  </p>

                  <PaymentOptions />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default FormaPagamento
