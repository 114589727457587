import Seo from 'src/components/seo/Seo'
import FormaPagamento from 'src/components/servicos/ServicesPage/FormaPagamento'

function Page() {
  return (
    <>
      <Seo title="Formas de Pagamento | Decathlon" />
      <FormaPagamento />
    </>
  )
}

export default Page
